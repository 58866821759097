import contactUs from 'assets/imgs/contact-us.jpg';
import Vector from 'components/ui/ٰVector';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import Container from 'components/ui/Container';
import ContactForm from 'components/ContactForm';
export default function ContactUs() {
  useDocumentTitle('ارتباط با ما');
  return (
    <Container>
      <Vector vector={contactUs} />
      <ContactForm />
    </Container>
  );
}
