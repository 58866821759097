import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/bundle';
import './QueteSlider.scss';
import quetes from 'components/Home/QueteSlider/quetes';
import SwiperCore, { Autoplay } from 'swiper';

export const QueteSlider: React.FC = () => {
  SwiperCore.use([Autoplay]);
  return (
    <Swiper
      slidesPerView={'auto'}
      pagination={{
        clickable: true
      }}
      autoplay={{ delay: 5000, disableOnInteraction: false }}
      spaceBetween={32}
      centeredSlides={true}
      loop={true}
      breakpoints={{
        768: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 3
        }
      }}
    >
      {quetes.map((item, index) => (
        <SwiperSlide key={index}>
          <p>{item}</p>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
