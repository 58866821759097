import { NavLink } from 'components/ui/Header/NavLinks/NavLink/NavLink';
import links from '../utils/links';
import './NavLinks.scss';
type Props = {
  nav: boolean;
  toggleNav: () => void;
};
export const NavLinks: React.FC<Props> = ({ nav, toggleNav }) => {
  return (
    <ul className={(nav && 'display') + ' nav-links'}>
      {links.map((item) => (
        <NavLink NavLink={item} toggleNav={toggleNav} key={item.name} />
      ))}
    </ul>
  );
};
