import Vector from 'components/ui/ٰVector';
import notFound from 'assets/imgs/404.svg';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { Link } from 'react-router-dom';
import 'theme/404.scss';
type Props = {
  pervPath?: string;
};
export const NotFound: React.FC<Props> = ({ pervPath }) => {
  useDocumentTitle('صفحه مورد نظر پیدا نشد');
  return (
    <div className="not-found">
      <Vector vector={notFound} />
      <section className="return">
        <h1>404</h1>
        <h2>صفحه مورد نظر یافت نشد</h2>
        <Link to={pervPath ? pervPath : '/'} className="btn">
          {/* بازگشت به خانه */}
          {pervPath ? 'بازگشت' : 'بازگشت به خانه'}
        </Link>
      </section>
    </div>
  );
};
