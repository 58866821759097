import BlogSuggestion from 'components/Blogs/BlogPage/BlogSuggestions/BlogSuggestion';
import { useSuggestionBlogs } from 'hooks/useData/useCategory/useSuggestionBlogs';
import './BlogSuggestions.scss';
export const BlogSuggestions: React.FC<{ category: string }> = ({ category }) => {
  const recentBlogs = useSuggestionBlogs();
  const suggestedBlogs = useSuggestionBlogs(category);
  return (
    <aside className="blog-suggestions">
      <BlogSuggestion {...recentBlogs} header="آخرین مطالب" />
      <BlogSuggestion {...suggestedBlogs} header="مطالب پیشنهادی" />
    </aside>
  );
};
