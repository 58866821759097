import { useBlogs } from 'hooks/useData/useBlog';
import { useEffect, useState } from 'react';

import SuggestionCardType from 'types/SuggestionCardType';

export const useSuggestionBlogs = (category?: string) => {
  const [suggestedBlogs, setSuggestedBlogs] = useState<SuggestionCardType[]>([]);
  const { blogs, status } = useBlogs(category);
  useEffect(() => {
    const data: SuggestionCardType[] = blogs.map((item) => ({
      title: item.title,
      img: item.img,
      slug: item.slug
    }));
    setSuggestedBlogs(data);
  }, [blogs]);

  return { suggestedBlogs, status };
};
