import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import IconDataType from 'components/ui/Footer/SocialList/types/IconDataType';
import useSocial from 'hooks/useData/useSocialItem';
import './SocialItem.scss';

const SocialItem: React.FC<IconDataType> = ({ icon, id }) => {
  const herf = useSocial(id);
  return (
    <li>
      <a href={herf} id={id} className={'social-item'}>
        <FontAwesomeIcon icon={icon} />
      </a>
    </li>
  );
};
export default SocialItem;
