import { categoriesUrl } from 'hooks/useData/urls/urls';
import useAxios from 'hooks/useData/useAxios';
import { useEffect, useState } from 'react';

export const useCategories = () => {
  const [categories, setCategories] = useState<string[]>([]);
  const { response, error, loading } = useAxios({ url: categoriesUrl });
  useEffect(() => {
    if (response) setCategories(response.data);
  }, [response]);
  const status = { error, loading };
  return { categories, status };
};
