import SocialList from 'components/ui/Footer/SocialList';
import './Footer.scss';
export const Footer = () => {
  return (
    <div className="footer">
      <SocialList />
      <p>© اپلیکیشن سلامت ‌فول برای سلامت ذهن و بدن</p>
    </div>
  );
};
