import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './BurgerMenu.scss';
type Props = {
  nav: boolean;
  toggleNav: () => void;
};
export const BurgerMenu: React.FC<Props> = ({ nav, toggleNav }) => {
  return (
    <FontAwesomeIcon
      icon={faBars}
      className={`burger-ico ${nav ? 'rotate-down' : ''}`}
      onClick={toggleNav}
    />
  );
};
