import FormValues from 'components/ContactForm/type/FormValues';
import { postFormUrl } from 'hooks/useData/urls/urls';
import useAxios from 'hooks/useData/useAxios';
import { useEffect, useState } from 'react';

export const usePostForm = (data: FormValues) => {
  const [post, setPost] = useState(false);
  const [status, setStatus] = useState<number>();
  const { response, loading, error } = useAxios(
    {
      url: postFormUrl,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data
    },
    post
  );
  useEffect(() => {
    setStatus(response?.status);
  }, [response]);
  useEffect(() => {
    if (response || error)
      setInterval(() => {
        setPost(false);
      }, 1000);
  }, [response, error]);
  return { status, loading, error, setPost, post };
};
