import BlogImage from 'components/Blogs/shared/BlogImage';
import { MouseEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import SuggestionCardType from 'types/SuggestionCardType';
import './SuggestionCard.scss';

export const SuggestionCard: React.FC<SuggestionCardType> = ({ title, slug, img }) => {
  const { slug: paramsSlug } = useParams();
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (slug === paramsSlug) e.preventDefault();
  };
  return (
    <Link to={'/blogs/blog/' + slug} className="suggestion-card" onClick={handleClick}>
      <section>
        <h3>{title}</h3>
      </section>
      <BlogImage path={img?.path} name={img?.name} />
    </Link>
  );
};
