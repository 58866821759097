import { blogsUrl as url } from 'hooks/useData/urls/urls';
import useAxios from 'hooks/useData/useAxios';
import { useBlogsByCategory } from 'hooks/useData/useCategory/useBlogsByCategory';
import { useEffect, useState } from 'react';
import BlogType from 'types/BlogType';

export const useBlogs = (category?: string) => {
  const [blogsState, setBlogsState] = useState<BlogType[]>([]);
  const filteredBlogs = useBlogsByCategory(category, blogsState);
  const { response, loading, error } = useAxios({ url });
  const status = { loading, error };

  useEffect(() => {
    if (response) {
      setBlogsState(response.data);
    }
  }, [response]);

  return { blogs: filteredBlogs, status };
};
