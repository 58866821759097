import { useEffect, useState } from 'react';
import Headroom from 'react-headroom';
import useMediaQuery from '../../../hooks/useMediaQuery';
import BurgerMenu from 'components/ui/Header/BurgerMenu';
import Logo from 'components/ui/Header/Logo';
import NavLinks from 'components/ui/Header/NavLinks';
import './Header.scss';
import { Link } from 'react-router-dom';

export const Header: React.FC = () => {
  const [nav, setNav] = useState(false);
  const tablet = useMediaQuery('(min-width: 768px)');
  const mobile = useMediaQuery('(max-width: 768px)');
  const toggleNav = () => {
    setNav((state) => !state);
  };
  useEffect(() => {
    if (tablet && nav) {
      setNav(false);
    }
  }, [tablet, nav]);

  return (
    <Headroom className="headroom" disable={mobile}>
      <div className="haeder-logo-section">
        {!tablet && <BurgerMenu nav={nav} toggleNav={toggleNav} />}
        <Link to={'/'} className="nav-logo">
          <Logo />
        </Link>
      </div>
      <NavLinks nav={nav} toggleNav={toggleNav} />
    </Headroom>
  );
};
