import BlogDetails from 'components/Blogs/shared/BlogDetails';
import BlogImage from 'components/Blogs/shared/BlogImage';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import NotFound from 'components/ui/NotFound';
import useMediaQuery from 'hooks/useMediaQuery';
import BlogType from 'types/BlogType';
import './BlogSection.scss';
import Status from 'types/Status';

type Props = {
  blog: BlogType;
  status: Status;
};
export const BlogSection: React.FC<Props> = ({ blog, status }) => {
  const desktop = useMediaQuery('(min-width: 1024px)');
  if (status.loading || status.error)
    return (
      <div className="blog-section">
        {status.loading && <LoadingSpinner />}
        {status.error && <NotFound pervPath="/blogs" />}
      </div>
    );
  return (
    <div className="blog-section">
      <header className="blog-header">
        {desktop && <BlogDetails date={blog.cDate} category={blog.cats} />}
        <h1>{blog.title}</h1>
      </header>
      <BlogImage path={blog.img?.path} name={blog.img?.name} />
      <div className="blog-content" dangerouslySetInnerHTML={{ __html: blog.desc.trim() }} />
      {!desktop && <BlogDetails date={blog.cDate} category={blog.cats} />}
    </div>
  );
};
