import { useEffect, useState } from 'react';
import BlogType from 'types/BlogType';

export const useBlogsByCategory = (category: string | undefined, blogs: BlogType[]) => {
  const [filteredBlogs, setFilteredBlogs] = useState<BlogType[]>([]);
  useEffect(() => {
    const newBlogs = blogs.filter((index) => index.cats === category);
    if (category) {
      setFilteredBlogs(newBlogs);
    } else {
      setFilteredBlogs(blogs);
    }
  }, [category, blogs]);
  return filteredBlogs;
};
