import { MouseEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import './BlogDetails.scss';

type Props = {
  date: Date;
  category: string;
  className?: string;
};
export const BlogDetails: React.FC<Props> = ({ date, category, className }) => {
  const { category: slug } = useParams();
  const persianDate = new Date(date).toLocaleDateString('fa-IR');
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (slug === category) e.preventDefault();
  };
  return (
    <section className={'blog-details ' + className}>
      <span>{persianDate}</span>
      <span className="dot" />
      <Link to={'/blogs/' + category} className="category" onClick={handleClick}>
        {category}
      </Link>
    </section>
  );
};
