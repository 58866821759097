import './ContactForm.scss';
import { useForm, Resolver, SubmitHandler } from 'react-hook-form';
import FormValues from 'components/ContactForm/type/FormValues';
import { useEffect } from 'react';
import { usePostForm } from 'components/ContactForm/hook/usePostForm';
const resolver: Resolver<FormValues> = async (values) => {
  return {
    values: values.name && values.email && values.subject && values.message ? values : {},
    errors: !values.name
      ? {
          name: {
            type: 'required',
            message: 'This is required.'
          }
        }
      : !values.subject
      ? {
          subject: {
            type: 'required',
            message: 'This is required.'
          }
        }
      : !values.email
      ? {
          email: {
            type: 'required',
            message: 'This is required.'
          }
        }
      : !values.message
      ? {
          message: {
            type: 'required',
            message: 'This is required.'
          }
        }
      : {}
  };
};
export const ContactForm = () => {
  const { register, handleSubmit, getValues, reset } = useForm<FormValues>({
    resolver
  });
  const { status, loading, error, setPost, post } = usePostForm(getValues());
  const onSubmit: SubmitHandler<FormValues> = () => {
    setPost(true);
  };
  useEffect(() => {
    if (!loading) reset();
  }, [reset, loading]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
      <div className="form-inputs">
        <input
          {...register('name')}
          type="text"
          name="name"
          id="name"
          placeholder="نام"
          autoComplete="off"
          defaultValue=""
        />
        <input
          {...register('subject')}
          type="text"
          name="subject"
          id="subject"
          placeholder="موضوع"
          autoComplete="off"
          defaultValue=""
        />
        <input
          {...register('email')}
          type="email"
          name="email"
          id="email"
          placeholder="ایمیل"
          defaultValue=""
        />
        <textarea
          {...register('message')}
          name="message"
          id="message"
          cols={30}
          rows={10}
          placeholder="پیام"
          autoComplete="off"
          defaultValue=""
        />
      </div>
      <button type="submit" value="ارسال" name="submit" id="submit">
        {!post && ' ارسال'}
        {post && loading && ' در حال ارسال'}
        {post && status && ' ارسال شد'}
        {error && ' مشکلی پیش آمد'}
      </button>
    </form>
  );
};
