import { ReactNode } from 'react';
import './Container.scss';

type Props = {
  children: ReactNode;
  reverseMobile?: boolean;
  tabletColumn?: boolean;
  alignStart?: boolean;
};
export const Container: React.FC<Props> = ({
  children,
  reverseMobile = false,
  tabletColumn = false,
  alignStart = false
}) => {
  return (
    <div
      className={
        'container' +
        `${reverseMobile ? ' reverse-mobile' : ''} ${tabletColumn ? 'tablet-column' : ''} `
      }
      style={{ alignItems: `${alignStart && 'flex-start'}` }}
    >
      {children}
    </div>
  );
};
