import BlogCard from 'components/Blogs/AllBlogs/BlogList/BlogCard';
import LoadingBlogCard from 'components/Blogs/AllBlogs/BlogList/LoadingBlogCard';
import { useBlogs } from 'hooks/useData/useBlog';
import { useParams } from 'react-router-dom';
import './BlogList.scss';

export const BlogList: React.FC = () => {
  const { category } = useParams<{ category: string }>();
  const { blogs, status } = useBlogs(category);

  if (status.error) {
    return <>{status.error.message}</>;
  }
  if (status.loading) {
    return (
      <div className="blog-list-container">
        <LoadingBlogCard />
        <LoadingBlogCard />
        <LoadingBlogCard />
        <LoadingBlogCard />
      </div>
    );
  }
  return (
    <div className="blog-list-container">
      {blogs.map((blog) => (
        <BlogCard {...blog} key={blog._id} />
      ))}
    </div>
  );
};
