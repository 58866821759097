import SuggestionCard from 'components/Blogs/BlogPage/BlogSuggestions/BlogSuggestion/SuggestionCard';
import SuggestionCardType from 'types/SuggestionCardType';

export const SuggestionList: React.FC<{ blogs: SuggestionCardType[] }> = ({ blogs }) => {
  const SuggestionBlogs = blogs.map((item, index) => {
    if (index <= 3)
      return <SuggestionCard title={item.title} img={item.img} key={index} slug={item.slug} />;
    else return [];
  });
  return <>{SuggestionBlogs}</>;
};
