import { useCategories } from 'hooks/useData/useCategory/useCategories';
import { ChangeEvent, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './BlogCategory.scss';

export const BlogCategory = () => {
  const { category } = useParams();
  const { categories } = useCategories();
  const navigate = useNavigate();
  const optionRef = useRef<HTMLOptionElement>(null);
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const category = e.target.value;
    const firstOptionValue = optionRef.current?.value;
    category === firstOptionValue ? navigate('/blogs') : navigate(category);
  };
  return (
    <div className="select-dropdown" dir="rtl">
      <select onChange={handleChange} value={category} defaultValue={optionRef.current?.value}>
        <option ref={optionRef}>همه</option>
        {categories.map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>
    </div>
  );
};
