import './BlogImage.scss';
type Props = {
  card?: boolean;
  path: string;
  name: string;
};
export const BlogImage: React.FC<Props> = ({ path, name, card = false }) => {
  return (
    <div className={`blog-image-wrapper ${card && 'card'}`}>
      <img src={path} alt={name} className="blog-image" />
    </div>
  );
};
