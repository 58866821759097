import SocialItem from 'components/ui/Footer/SocialList/SocialItem';
import icons from 'components/ui/Footer/SocialList/utils/icons';
import './SocialList.scss';
export const SocialList = () => {
  return (
    <ul className={'social'}>
      {icons.map((item) => (
        <SocialItem key={item.id} icon={item.icon} id={item.id} />
      ))}
    </ul>
  );
};
