import { socialListUrl } from 'hooks/useData/urls/urls';
import useAxios from 'hooks/useData/useAxios';
import { useEffect, useState } from 'react';
import { SocialItem } from 'types/SocialItem';

export const useSocialItem = (socialItem: string) => {
  const [herf, setHerf] = useState('/#');
  const { response } = useAxios({ url: socialListUrl });
  useEffect(() => {
    if (response) {
      response.data.map((item: SocialItem) => {
        if (item.type === socialItem) setHerf(item.url);
      });
    }
  }, [response, socialItem]);

  return herf;
};
