import useAxios from 'hooks/useData/useAxios';
import { singleBlogUrl } from 'hooks/useData/urls/urls';
import BlogType from 'types/BlogType';
import { useEffect, useState } from 'react';

const initialState: BlogType = {
  lock: false,
  _id: '',
  cDate: new Date(),
  title: '',
  slug: '',
  courseId: '',
  author: '',
  pDate: new Date(),
  cats: '',
  meta: '',
  desc: '',
  img: { name: '', mime: '', path: '' },
  __v: 0
};
export const useSingleBlog = (slug: string | undefined) => {
  const [blog, setBlog] = useState<BlogType>(initialState);
  const { response, loading, error } = useAxios({ url: singleBlogUrl + slug });
  useEffect(() => {
    response && setBlog(response.data);
  }, [response]);

  const status = { loading, error };
  return { blog, status };
};
