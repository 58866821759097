import logo from 'assets/imgs/salamatfulLogo.svg';
import './Logo.scss';

export const Logo = () => {
  return (
    <section className="nav-logo">
      <h1>سلامت فول</h1>
      <img src={logo} alt="salamt" className="logo" />
    </section>
  );
};
