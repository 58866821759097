import { NavLink as Link, useMatch } from 'react-router-dom';
import NavLinkType from '../types/NavLinkType';
import './NavLink.scss';

type Props = {
  NavLink: NavLinkType;
  toggleNav: () => void;
};
export const NavLink: React.FC<Props> = ({ NavLink, toggleNav }) => {
  const match = useMatch(NavLink.route === 'blogs' ? NavLink.route + '/*' : NavLink.route);
  const isActive = Boolean(match);
  return (
    <li>
      <Link
        to={NavLink.route}
        className={(isActive ? 'nav-link-focus ' : '') + 'nav-link'}
        onClick={toggleNav}
      >
        {NavLink.name}
      </Link>
    </li>
  );
};
