import '../../BlogList/BlogCard/BlogCard.scss';
import './LoadingBlogCard.scss';
import transparent from 'assets/imgs/transparent.png';
import BlogImage from 'components/Blogs/shared/BlogImage';
export const LoadingBlogCard = () => {
  return (
    <div className="loading-card-container">
      <BlogImage path={transparent} name="loading" card />
      <section className="card-section">
        <h2>
          <br />
        </h2>
        <div className="details">
          <br />
        </div>
        <p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </p>
      </section>
      <a className="card-btn">
        <br />
      </a>
    </div>
  );
};
