import Container from 'components/ui/Container';
import BlogSection from 'components/Blogs/BlogPage/BlogSection';
import BlogSuggestions from 'components/Blogs/BlogPage/BlogSuggestions';
import { useSingleBlog } from 'hooks/useData/useBlog';
import { useParams } from 'react-router-dom';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const Blog = () => {
  const { slug } = useParams();
  const blogResponse = useSingleBlog(slug);
  useDocumentTitle(blogResponse.blog.title || 'وبلاگ');
  return (
    <Container reverseMobile tabletColumn alignStart>
      <BlogSuggestions category={blogResponse.blog.cats} />
      <BlogSection {...blogResponse} />
    </Container>
  );
};
export default Blog;
