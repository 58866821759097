import './BlogCard.scss';
import { Link } from 'react-router-dom';
import BlogDetails from 'components/Blogs/shared/BlogDetails';
import BlogImage from 'components/Blogs/shared/BlogImage';
import React from 'react';
import BlogType from 'types/BlogType';

function propsAreEqual(perv: BlogType, next: BlogType) {
  return perv._id === next._id;
}
export const BlogCard = React.memo((props: BlogType) => {

  return (
    <div className="card-container">
      <BlogImage path={props.img?.path} name={props.img?.name} card />
      <section className="card-section">
        <h2>{props.title}</h2>
        <BlogDetails date={props.pDate} category={props.cats} />
        <p className="section-p">{props.meta}</p>
      </section>
      <Link to={'blog/' + props.slug} className="card-btn">
        ادامه مطلب
      </Link>
    </div>
  );
}, propsAreEqual);
BlogCard.displayName;
