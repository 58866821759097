import Footer from 'components/ui/Footer';
import Header from 'components/ui/Header';
import ScrollToTop from 'components/ui/ScrollToTop';
import { ReactNode } from 'react';
import './Layout.scss';

export const Layout: React.FC = (props: React.PropsWithChildren<ReactNode>) => {
  return (
    <div className="div">
      <Header />
      <ScrollToTop />
      <main className="main">{props.children}</main>
      <Footer />
    </div>
  );
};
