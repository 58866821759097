import BlogCategory from 'components/Blogs/AllBlogs/BlogCategory';
import BlogList from 'components/Blogs/AllBlogs/BlogList';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

export default function Blogs() {
  useDocumentTitle('وبلاگ');
  return (
    <>
      <BlogCategory />
      <BlogList />
    </>
  );
}
