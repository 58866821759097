import { Route, Routes } from 'react-router-dom';
import AboutUs from 'pages/AboutUs';
import Blog from 'pages/Blog';
import Blogs from 'pages/Blogs';
import ContactUs from 'pages/ContactUs';
import Home from 'pages/Home';
import Layout from 'components/ui/Layout';
import NotFound from 'components/ui/NotFound';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<Blogs />}>
          <Route path=":category" element={<Blogs />} />
        </Route>
        <Route path="/about-Us" element={<AboutUs />} />
        <Route path="/contact-Us" element={<ContactUs />} />
        <Route path="/blogs/blog/:slug" element={<Blog />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}
export default App;
