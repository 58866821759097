import healthyPeople from 'assets/imgs/Healthy.svg';
import meditating from 'assets/imgs/Meditating.svg';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import Vector from 'components/ui/ٰVector';
import Container from 'components/ui/Container';
import QueteSlider from 'components/Home/QueteSlider';
import HeaderSection from 'components/ui/HeaderSection';
import HomeButtons from 'components/Home/HomeButtons';

/*eslint no-irregular-whitespace: "off"*/
const Home: React.FC = () => {
  useDocumentTitle();
  return (
    <>
      <Container>
        <Vector vector={healthyPeople} />
        <HeaderSection>
          <h1>​سلامت ذهن و سلامت بدن برای تجربه ی زندگی شادتر</h1>
          <p>
            تمرینات ورزشی برای همه سطوح آمادگی جسمانی و در مناسب ترین بازه های ممکن در کنار جلسات
            مدیتیشن با راهنما و موضوعات مختلف
          </p>
        </HeaderSection>
      </Container>
      <Container>
        <HomeButtons />
        <Vector vector={meditating} />
      </Container>
      <QueteSlider />
    </>
  );
};
export default Home;
