import useMediaQuery from 'hooks/useMediaQuery';
import { useEffect } from 'react';
import { isSafari } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  const desktop = useMediaQuery('(min-width: 1023px)');

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: isSafari && !desktop ? 'auto' : 'smooth' });
  }, [pathname, desktop]);

  return null;
};
