import aboutUs from 'assets/imgs/about-us.svg';
import Vector from 'components/ui/ٰVector';
import Container from 'components/ui/Container';
import HeaderSection from 'components/ui/HeaderSection';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const AboutUs = () => {
  useDocumentTitle('درباره ما');
  return (
    <Container reverseMobile>
      <Vector vector={aboutUs} />
      <HeaderSection>
        <h1>درباره ما</h1>
        <p>وبلاگ سلامت فول</p>
      </HeaderSection>
    </Container>
  );
};

export default AboutUs;
