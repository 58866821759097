import NavLinkType from '../types/NavLinkType';

const link = (name: string, route: string) => {
  return { name, route };
};

const names: string[] = ['خانه', 'وبلاگ', 'درباره ما', 'ارتباط با ما'];
const routes: string[] = ['', 'blogs', 'about-us', 'contact-us'];
const links: NavLinkType[] = names.map((name, index) => link(name, routes[index]));
export default links;
