import { Link } from 'react-router-dom';
import './HomeButtons.scss';
export const HomeButtons = () => {
  return (
    <div className="home-btns">
      <a href="https://app.salamatful.com" className="home-btn">
        ورود به اپلیکیشن
      </a>
      <Link to="/Blogs" id="blog-link" className="home-btn">
        وبلاگ سلامت فول
      </Link>
    </div>
  );
};
