import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import IconDataType from '../types/IconDataType';

const icos = [faInstagram, faWhatsapp, faTelegram];
const ids = ['instagram', 'whatsapp', 'telegram'];

const icons = icos.map((ico, index) => {
  const item: IconDataType = {
    icon: ico,
    id: ids[index]
  };
  return item;
});

export default icons;
