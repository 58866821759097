import SuggestionList from 'components/Blogs/BlogPage/BlogSuggestions/BlogSuggestion/SuggestionList';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import Status from 'types/Status';
import SuggestionCardType from 'types/SuggestionCardType';
import './BlogSuggestion.scss';
type Props = {
  header: string;
  status: Status;
  suggestedBlogs: SuggestionCardType[];
};
export const BlogSuggestion: React.FC<Props> = ({ header, suggestedBlogs, status }) => {
  return (
    <div className="blog-suggestion">
      <h2 className="title">{header}</h2>
      {status.error && <div>{status.error.message}</div>}
      {status.loading ? <LoadingSpinner /> : <SuggestionList blogs={suggestedBlogs} />}
    </div>
  );
};
