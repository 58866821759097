import { useState, useEffect } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import baseUrl from 'hooks/useData/urls';

axios.defaults.baseURL = baseUrl;

const useAxios = (axiosParams: AxiosRequestConfig, auto = true) => {
  const [response, setResponse] = useState<AxiosResponse>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(true);
  const fetchData = async (params: AxiosRequestConfig) => {
    setLoading(true);
    setError(undefined);
    try {
      const result = await axios.request(params);
      if (result.data.message === 'Error') throw Error('Error');
      if (result.status === 404) throw Error('Not Found');
      setResponse(result);
    } catch (error) {
      const err = error as AxiosError;
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  /*eslint react-hooks/exhaustive-deps:'off'*/
  useEffect(() => {
    if (auto) {
      fetchData(axiosParams);
    }
    if (!auto) {
      setResponse(undefined);
      setError(undefined);
      setLoading(true);
    }
    return () => {
      setResponse(undefined);
      setError(undefined);
      setLoading(true);
    };
  }, [axiosParams.url, auto]);

  return { response, error, loading };
};
export default useAxios;
